export const LOGINSUCCESS = "LOGINSUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const TEAMNAME = "TEAMNAME";
export const CITYNAME = "CITYNAME";
export const MATCHINFOR = "MATCHINFOR";
export const ENTRYDATA = "ENTRYDATA";
export const EACHENTRYDATA = "EACHENTRYDATA";
export const GETLEADERBOARD = "GETLEADERBOARD";
export const EACHCLEAR = "EACHCLEAR";
