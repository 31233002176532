import { LOGINSUCCESS, LOGOUT, REGISTER_SUCCESS } from '../constants';

const initState = {
  isAuthenticated: false,
  user: {}
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      }
    case LOGINSUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      }
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {}
      }
    default:
      return state;
  }
};