import { TEAMNAME, CITYNAME, MATCHINFOR, ENTRYDATA, EACHENTRYDATA, EACHCLEAR } from '../constants';

const initState = {
  citynames: [],
  teamname: [],
  match: [],
  entrydata: [],
  eachentrydata: []
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case CITYNAME:
      return {
        ...state,
        citynames: payload
      }
    case TEAMNAME:
      return {
        ...state,
        teamname: payload
      }
    case MATCHINFOR:
      return {
        ...state,
        match: payload
      }
    case ENTRYDATA:
      return {
        ...state,
        entrydata: payload
      }
    case EACHENTRYDATA:
      return {
        ...state,
        eachentrydata: payload
      }
    case EACHCLEAR:
      return {
        ...state,
        eachentrydata: payload
      }
    default:
      return state;
  }
};