import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const MatchSchedule = React.lazy(() => import("./pages/match-schedule"));
const Matchup = React.lazy(() => import("./pages/matchup"));
const Login = React.lazy(() => import("./pages/login"));
const Register = React.lazy(() => import("./pages/register"));
const ComfirmUser = React.lazy(() => import("./component/register/confirm"));
const Leaderboard = React.lazy(() => import("./pages/leaderboard"));
const Profile = React.lazy(() => import("./pages/profile"));
// const Teammatch = React.lazy(() => import('./component/Admin/Match'));
const ManageUsers = React.lazy(() => import("./pages/manage-users"));
const ManageEntries = React.lazy(() => import("./pages/manage-entries"));
const Payment = React.lazy(() => import("./component/Payment"));
const EntryHistory = React.lazy(() => import("./component/EntryHistory"));
const ForgetPass = React.lazy(() => import("./pages/forget-pass"));
const ResetPass = React.lazy(() => import("./component/ResetPass"));
const TermsUse = React.lazy(() => import("./pages/terms-use"));
const Rules = React.lazy(() => import("./pages/rules"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacy-policy"));
const Support = React.lazy(() => import("./pages/support"));
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

export default class App extends Component {
  render() {
    return (
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/"
              name="Home"
              render={(props) => <Leaderboard {...props} />}
            />
            <Route
              exact
              path="/match-schedule"
              name="MatchSchedule"
              render={(props) => <MatchSchedule {...props} />}
            />
            <Route
              exact
              path="/matchup"
              name="Matchup"
              render={(props) => <Matchup {...props} />}
            />
            <Route
              exact
              path="/login"
              name="Login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="register"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/profile"
              name="profile"
              render={(props) => <Profile {...props} />}
            />
            <Route
              exact
              path="/manage-users"
              name="manage-users"
              render={(props) => <ManageUsers {...props} />}
            />
            <Route
              exact
              path="/manage-entries"
              name="manage-entries"
              render={(props) => <ManageEntries {...props} />}
            />
            {/* <Route
              exact
              path="/donate"
              name="payment"
              render={(props) => <Payment {...props} />}
            /> */}
            <Route
              exact
              path="/history"
              name="history"
              render={(props) => <EntryHistory {...props} />}
            />
            <Route
              exact
              path="/forgetpass"
              name="forgetpass"
              render={(props) => <ForgetPass {...props} />}
            />
            <Route
              exact
              path="/passwordReset/:token"
              name="passwordReset"
              render={(props) => <ResetPass {...props} />}
            />
            <Route
              exact
              path="/confirm/:confirmCode"
              name="confirmUser"
              render={(props) => <ComfirmUser {...props} />}
            />
            <Route
              exact
              path="/terms-of-use"
              name="TermsUse"
              render={(props) => <TermsUse {...props} />}
            />
            <Route
              exact
              path="/rules"
              name="Rules"
              render={(props) => <Rules {...props} />}
            />
            <Route
              exact
              path="/privacy-policy"
              name="PrivacyPolicy"
              render={(props) => <PrivacyPolicy {...props} />}
            />
            <Route
              exact
              path="/support"
              name="Support"
              render={(props) => <Support {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}
